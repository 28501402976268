/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// HYPERLINK - START

a.AClear
	color: inherit
	text-decoration: none
	border: none

a.AClear:link
	color: inherit
	text-decoration: none
	border: none

a.AClear:visited
	color: inherit
	text-decoration: none
	border: none

a.AClear:hover
	color: inherit
	text-decoration: none
	border: none

a.AClear:active
	color: inherit
	text-decoration: none
	border: none

a.AUnderline
	color: inherit
	text-decoration: underline

a.AUnderline:link
	color: inherit
	text-decoration: underline

a.AUnderline:visited
	color: inherit
	text-decoration: underline

a.AUnderlinek:hover
	color: inherit
	text-decoration: underline

a.AUnderline:active
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:link
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:visited
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:hover
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownloade:active
	color: inherit
	text-decoration: underline
	font-weight: 600

nav
	figure
		a
			padding: $SPACE_TINY
			display: inline-block

a.ForgotPassword
	float: right
	margin-top: $SPACE_LITTLE
	margin-bottom: $SPACE_LITTLE
	// font-size: $fontsize_link_general
	display: block
	text-decoration: underline

a.SignUp
	text-decoration: underline

// HYPERLINK - END


// GENERAL - START

div#divNotificationInstall, div#divNotificationInstallList
	input[type=button]#buttonNotificationInstall, input[type=button]#buttonNotificationInstallList
		margin-left: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: 200px
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=button]#buttonNotificationInstallList
		margin: $SPACE_SMALL auto 0px auto
	input[type=button]#buttonNotificationClose
		background: none
		width: $HEIGHT_LINK_BUTTON
		height: $HEIGHT_LINK_BUTTON
		border: none
		background-repeat: no-repeat
		background-size: cover
		margin-right: $SPACE_LITTLE
		padding: $SPACE_TINY
		+setBorderRadius($RADUIS_LINK_BUTTON)
		flex-shrink: 0

form
	input[type=button]
		margin-top: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: 100%
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=button]:first-of-type
		margin-top: $SPACE_MEDIUM
	input[type=button]:last-of-type
		margin-bottom: $SPACE_MEDIUM
	input[type=button]#rightButton
		margin-top: $SPACE_LITTLE
		width: 20%
		float: right
	input[type=button]#rightButton
		margin-bottom: $SPACE_LITTLE
		width: 20%
		float: right
	div.DivFormButtonContainer
		display: flex
		justify-content: space-between
		input[type=button]
			margin: $SPACE_LITTLE
		input[type=button]:first-of-type
			margin-left: 0px
		input[type=button]:last-of-type
			margin-right: 0px
	div.FlexCenter
		justify-content: center
	div.DivEmailNotRegistered
		display: flex
		flex-direction: column
		p
			align-items: center
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE

input[type=button].ButtonCopy, input[type=button].ButtonDelete, input[type=button].ButtonPDF, input[type=button].ButtonRemove, input[type=button].ButtonAttachment, input[type=button].ButtonDownload, input[type=button].ButtonAddTicket, input[type=button].ButtonSwitch, input[type=button].ButtonEdit, input[type=button].ButtonAdd, input[type=button].ButtonTableAdd, input[type=button].ButtonButtonAdd
	width: $SIZE_ICON_LDPI
	height: $SIZE_ICON_LDPI
	vertical-align: middle
	display: inline-block
	background-size: contain
	background-position: center
	background-repeat: no-repeat
	border: none

input[type=button].ButtonAttachment
	margin: 0px
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI

input[type=button].ButtonAddTicket
	width:$SIZE_ICON_MDPI
	height: $SIZE_ICON_MDPI
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_MDPI $SIZE_ICON_MDPI

form
	input[type=button].inputAbout
		display: block
		margin-top: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: auto
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=file]
		display: none

form input[type=button].ButtonDelete
	margin: 0px
	+setBorderRadius($RADUIS_LINK_BUTTONTABLE)

// GENERAL - END


// HOME - START

input[type=button]#buttonNavigation
	position: absolute
	top: 40px
	left: 40px
	width: 30px
	height: 30px
	border: none

ul.ListNavigation
	li.ListItemNavigation, li.ListItemSubNavigation
		a, label
			border-radius: $RADIUS_BUTTON_GENERAL
			&:hover
				padding: $SPACE_SMALL
		div.DivListItemNavigationTwoLine, div.DivListItemSubNavigationTwoLine
			border-radius: $RADIUS_BUTTON_GENERAL
		div.DivListItemNavigationTwoLine:hover, div.DivListItemSubNavigationTwoLine:hover
			padding: $SPACE_SMALL
			max-width: 140px
			cursor: pointer

// HOME - END


// TABLE - START

div.DivTableControl, div.DivListControl, div.DivTableContainer
	div.DivButtonFirst, div.DivButtonPrevious, div.DivButtonNext, div.DivButtonLast, input[type=button], div.DivButton, div.DivButtonVideDetail
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		border: none
		background-repeat: no-repeat
		background-size: cover
	div.DivButtonFirst, div.DivButtonPrevious, div.DivButtonNext, div.DivButtonLast, div.DivButton
		margin-left: $SPACE_TINY
		margin-right: $SPACE_TINY
		+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
		display: inline-block
	div.DivButtonVideDetail
		margin-left: $SPACE_TINY
		margin-right: $SPACE_TINY
		+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
		display: flex
		align-items: center
	div.TableButtonContainer
		text-align: center
	input[type=button].ButtonDownload, input[type=button].ButtonRemove, input[type=button].ButtonEdit, input[type=button].ButtonAdd
		margin-top: 0px
		background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI
	form.FormButtonContainerInnerTable
		width: 100%
		margin-top: $SPACE_ZERO
		div.DivButtonContainerInnerTable
			input[type=button]:first-of-type
				margin-top: $SPACE_ZERO
			input[type=button]
				width: 100%
				height: $HEIGHT_LINK_BUTTON
div.DivTableContainer
	ul.ListTableThumbnails
		div.DivButton
			margin-top: $SPACE_LITTLE
		input[type=button].ButtonDownload, input[type=button].ButtonRemove, input[type=button].ButtonEdit, input[type=button].ButtonAdd
			margin: 0px
	table.TableListDetails
		td
			div.DivButton
				margin-left: 0px
				height: $SPACE_HUGE
				width: $SPACE_HUGE
				display: flex
				align-content: center
				flex-direction: column
				align-items: center
				+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
				input[type=button].ButtonRemove, input[type=button].ButtonDownload, input[type=button].ButtonEdit, input[type=button].ButtonAdd
					margin: 0px
					background-size: $SIZE_LINK_BUTTONATTACHMENT $SIZE_LINK_BUTTONATTACHMENT
					margin-top: 0px
	table.TableListDetailsAttachment
		input[type=button].ButtonRemove, input[type=button].ButtonDownload, input[type=button].ButtonAttachment, input[type=button].ButtonEdit, input[type=button].ButtonAdd, input[type=button].ButtonTableAdd, input[type=button].ButtonButtonAdd
			margin: 0px -0.25px
			background-size: $SIZE_LINK_BUTTONATTACHMENT $SIZE_LINK_BUTTONATTACHMENT


ul.ListTableThumbnails
	li
		cursor: pointer
		cursor: hand

div.DivInnerDynamicContainer
	form
		div.DivTableContainer
			table
				tbody
					tr
						td.TableButtonContainer
							input[type=button]
								margin: 0px

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivListControl
	div.DivListControlButton
		input[type=button]
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			border: none
			background-repeat: no-repeat
			background-size: cover
			margin: $SPACE_TINY
			+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
			display: inline-block
		input[type=button]:first-of-type
			margin-left: $SPACE_TINY
		input[type=button]:last-of-type
			margin-right: $SPACE_TINY
	div.DivNavigationControlButton
		input[type=radio]
			display: none
		input[type=radio] + label
			width: 120px
			height: 30px
			border: none
			text-align: center
			padding-top: 10px
			background-repeat: no-repeat
			background-size: cover
			margin: $SPACE_LITTLE
			font-family: $URL_FAMILYPRIMARY_PRIMARY
			font-size: 1em
			+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
			display: inline-block
		label
			margin-left: auto
			margin-right: auto

// DYNAMIC CONTAINER - END


// BUTTON FOR UPLOAD DOCUMENT e-METERAI START

div.DivSingleForm
	form
		div.ButtonSubmitUploadDocument
			margin-top: -285px

// BUTTON FOR UPLOAD DOCUMENT e-METERAI END

div.DivMainAsideSearch
	div.DivTableContainer
		input[type=button].ButtonCancel
			background-color: #F7931E
			border-radius: 10%
			color: white
			font-size: 12px
			height: 30px
			padding: 10px
			width: 60px
			padding-top: 8px


// CONTAINER FILE ATTACHMENT DETAIL START

div#divMainDashboard
	div.DivDynamicContainer
		form
			div.DivTableContainer
				div.DivContainerAttachedment
					ul.ListTableThumbnails
						li
							figure
								// background-color: red !important
								div.DivButton
									background-color: yellow
									input[type=button].ButtonSwitch
										width: $SIZE_ICON_LDPI
										height: $SIZE_ICON_LDPI
										vertical-align: middle
										display: inline-block
										background-size: contain
										background-position: center
										background-repeat: no-repeat
										border: none
										background-size:24px 24px
									input[type=button].ButtonDownload
										background-size:24px 24px

// CONTAINER FILE ATTACHMENT DETAIL END


// GCG ONLINE - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					input[type=button]
						width: 49%
						margin-top: $SPACE_LITTLE

// GCG ONLINE - END


// E-Materai - START

div.DivButtonDashboardEmaterai
	input[type=button]
		width: 300px

// E-Materai - END


// E-BUDGETING - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					input[type=button]
						width: 49%
						margin-top: $SPACE_LITTLE
		div.DivMainAsideContent
			form
				input[type=button]
					margin-bottom: $SPACE_ZERO

// E-BUDGETING - END


// TOGGLE BUTTON - START

label.LabelToggle
	position: relative
	display: inline-block
	width: $WIDTH_INPUT_TOGGLE
	height: $HEIGHT_INPUT_TOGGLE
	input[type=checkbox].CheckboxToggle
		+setOpacity($SPACE_ZERO)
		width: $SPACE_ZERO
		height: $SPACE_ZERO
		&:checked + span.SpanSliderToggle:before
			+setTranslateX($TRANSLATE_BUTTON_TOGGLE)
	span.SpanSliderToggle
		position: absolute
		cursor: pointer
		top: $SPACE_ZERO
		left: $SPACE_ZERO
		right: $SPACE_ZERO
		bottom: $SPACE_ZERO
		+setTransition(all, 0.4, ease-out)
		+setBorderRadius($RADIUS_INPUT_TOGGLE)
		&::before
			position: absolute
			content: ""
			height: $HEIGHT_BUTTON_TOGGLE
			width: $WIDTH_BUTTON_TOGGLE
			left: 4px
			bottom: 4px
			background-color: white
			+setTransition(all, 0.4, ease-out)
			+setBorderRadius(50%)

// TOGGLE BUTTON - END


// CHECKBOX BUTTON - START

div.DivFormButtonContainer
	div.DivCheckBoxContainer
		display: flex
		align-items: center
		justify-content: center
		div.DivCheckBox
			width: $WIDTH_BUTTON_CHECkBOX
			height: $HEIGHT_BUTTON_CHECkBOX
			padding: $PADDING_INPUT_CHECKBOX
			+setBorderRadius($RADIUS_INPUT_CHECkBOX)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=checkbox]
				display: none
			figure
				img
					margin: $SPACE_ZERO
					width: $SPACE_ZERO
					height: $SPACE_ZERO		
			input[type=checkbox]:checked + figure img
				width: $WIDTH_INPUT_CHECkBOX
				height: $HEIGHT_INPUT_CHECkBOX
		div.DivCheckBox + figure img
			margin-right: $SPACE_MEDIUM
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW

// CHECKBOX BUTTON - END


// BRINSAVE - START

form
	div.DivForm
		div.DivShelfContainer
			div.DivShelfBoxContainer
				div.DivShelfBox
					input[type=button]
						padding: $SPACE_ZERO
						margin: $SPACE_ZERO
						+setBorderRadius($SPACE_ZERO)
					input[type=button].ButtonShelf
						width: 95.55px
						height: 82.67px
					input[type=button].ButtonArchiveLocation
						width: 95.55px
						height: 90px
					input[type=button].ButtonArchiveData
						width: 95.55px
						height: 90px

// BRINSAVE - END