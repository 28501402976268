/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1330px - START

@media screen and (max-width: 1330px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start
	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			div.DivAttachmentContainer
				display: flex
				justify-content: flex-end
	form.FormContainerCMS
		div.DivContainerAttachmentBanner
			div.DivContainerHorizontal
				display: flex
				flex-direction: row !important

// SCREEN WIDTH 1330px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyHome main
		padding: $SPACE_LARGE

	div.DivVerifierGeneral, div.DivCenterGeneral
		margin: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_LARGE

	input[type=button]#buttonNavigation
		left: $SPACE_LARGE

	div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
		margin-top: 60px

	div.DivMainAsideSearch
		form
			margin-right: $SPACE_LITTLE
			width: $WIDTH_LAYOUT_SIGNIN_COMPACT

	div.DivTableContainer
		margin-left: $SPACE_LITTLE

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 140px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 140px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

// SCREEN WIDTH 1250px - END


// SCREEN WIDTH 1140px - START

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div.DivVerifierGeneral, div.CenterGeneral
		margin: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.DivSubDynamicContainer
				margin-top: 0px

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivMainAsideSearch
		div.DivMainTable
			margin-left: $SPACE_ZERO

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivFormContainerDashboardBrinspro
				display: flex
				flex-direction: column
				div.DivFormFirstColumn
					width: 100%
					div.DivFormContainerProject
						div.DivFormProjectTotal
							div.DivProjectContent
								margin-top: 92px
								label.LabelProject
									margin-top: 100px
				div.DivFormSecondColumn
					width: 100%
			div.DivFormContainerDashboardBrinsproLast
				margin-top: 5px
				div.DivFormSecondColumnLast
					margin-top: 20px

// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivFormContainerDashboardBrinspro
				display: flex
				flex-direction: column
				div.DivFormFirstColumn
					width: 100%
					div.DivFormContainerProject
						div.DivFormProjectTotal
							div.DivProjectContent
								margin-top: 0px
								label.LabelProject
									margin-top: 55px
				div.DivFormSecondColumn
					width: 100%
			div.DivFormContainerDashboardBrinsproLast
				margin-top: 5px
				div.DivFormSecondColumnLast
					margin-top: 20px

// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivVerifierGeneral, div.DivCenterGeneral
		header.HeaderGeneral
			padding-top: 60px

	div.DivVerifierGeneral, div.DivCenterGeneral
		img#imageLogoHeader
			right: auto
			left: 0px

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			div.DivAttachmentContainer
				display: flex
				justify-content: flex-end
		div.DivDashboardEmeterai
			flex-direction: column
			text-align: center

	div.DivTableContainer
		table
			thead
				tr
					th
						word-break: break-all
			tbody, tfoot
				tr
					td
						word-break: break-all
		ul.ListTableThumbnails
			justify-content: space-around
		ul.ListTableConversation
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						justify-content: flex-end

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 80px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 80px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div#divBodyDashboard
		div#divMainDashboard
			div.DivSingleForm
					form
						div.DivButtonContainer
							input[type=button]
								width: 100%
	
	div.DivMainAsideSearch
		div.DivMainTable
			form
				div.DivButtonTableContainer
					input[type=button]
						width: 100%

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivFormContainerProjectDetail
				div.DivFormProjectDetail
					display: flex
					flex-direction: column
					div.DivTableProjectDetail
						width: 100%
					div.DivFormProgress
						width: 100%
			div.DivFormContainerDashboardBrinspro
				display: flex
				flex-direction: column
				div.DivFormFirstColumn
					width: 100%
					div.DivFormContainerProject
						div.DivFormProjectTotal
							div.DivProjectContent
								label.LabelProject
									margin-top: 37px
				div.DivFormSecondColumn
					width: 100%
			div.DivFormContainerDashboardBrinsproLast
				margin-top: 5px
				div.DivFormSecondColumnLast
					margin-top: 20px

// SCREEN WIDTH 800px - END


// SCREEN WIDTH 660px - START

@media screen and (max-width: 660px)
	form.FormPerformance
		ngx-charts-pie-chart
			ngx-charts-chart
				.ngx-charts-outer
					justify-content: center

	div#divBodyDashboard
			div.DivMainGeneral
				div.DivFormContainerDashboardBrinspro
					div.DivFormContainerProject
						display: flex
						flex-direction: column
						div.DivFormProject
							width: 100%

// SCREEN WIDTH 660px - END


// SCREEN WIDTH 640px - START

@media screen and (min-width: 641px)
	div#divNotificationInstall
		width: 640px
		height: auto
		left: auto
		position: fixed
		right: $SPACE_LITTLE
		bottom: $SPACE_LITTLE
		+setBorderRadius($RADIUS_INPUT_GENERAL)

@media screen and (max-width: 640px)
	body
		font-size: 95%
	div#divBodyHome img#imageLogoHeader.DisplayNone
		display: none

	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivContainer
				div.DivFormHorizontalContainer
					flex-direction: column
					align-content: center
					align-items: center

	div#divBodyDashboard
		div#divMainDashboard
			width: 100%

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				width: 100%

	div.DivForm, form div.DivForm fieldset
		.mat-form-field
			width: 100%

	div.DivTableContainer
		div.DivTableControl
			flex-direction: column
			div.DivTableSubControl, div.DivTableSubControlMaterai
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				div.DivMultipleLabelAttachment
					margin: 0
			th, td
				text-align: center

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 200px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)

	// SUMMARY DECLARATION START

	div.DivSummaryForm
		div.DivContentSummaryForm
			display: flex
			flex-direction: column !important

	// SUMMARY DECLARATION END


	// E-MATERAI - START

	div.DivBodyEmaterai
		flex-direction: column-reverse

	div.DivButtonDashboardEmaterai
		flex-direction: column-reverse
		align-items: center
		text-align: center

	// E-MATERAI - END


	// E-BUGETING - START

	div.DivBodyEbudgeting
		form.FormTransparent
			div.DivForm
				fieldset
					width: 100%
		div.DivForm
			div.DivFormHorizontalContainer > div
				width: 100%
				display: grid
				grid-template-columns: 1fr
				text-align: center
				div
					display: flex
					justify-content: center
				label
					margin-top: $SPACE_THIN
		div.DivEbudgetingSummary
			p
				width: 100%
		div.DivEbudgetingDaashboadDescription
			display: flex
			justify-content: center
			ul
				justify-content: flex-start
				width: min-content

	// E-BUGETING - END

	// BRINSAVE - START

	div.DivBodyFormTransparent
		form.FormTransparent
			div.DivForm
				fieldset
					width: 100%

	// BRINSAVE - END


	// BRINSPRO - START

	div#divBodyDashboard
		div.DivMainGeneral
			div.DivTableContainer
				table.TableWithInput
					td:nth-child(1)
						width: 100%
					td:nth-child(2)
						width: 100%

	// BRINSPRO - END

// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	div#divBodyDashboard
			div.DivMainGeneral
				div.DivFormContainerDashboardBrinsproDate
					.mat-mdc-form-field-infix
						width: 200px
				div.DivFormContainerDashboardBrinsproLast
					margin-top: 5px
					div.DivFormSecondColumnLast
						margin-top: 20px

// SCREEN WIDTH 490px - END
