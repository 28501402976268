/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-setup
@import form-theme-sakina-svg

// IMPORT - END


// GENERAL - START

form
	width: 100%
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			.divMatFormFieldPeriod
				width: 100%
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputHorizontalContainer
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				justify-content: space-between
		fieldset:last-of-type
			margin-bottom: 0px
		div.DivRadioButtonContainer
			div.DivSubRadioButtonContainer
				display: flex
				align-items: center
	ul.UlListAsignee
		display: flex
		flex-flow: row wrap
		align-content: flex-start
		justify-content: space-between
		li
			box-sizing: border-box
			margin: 0
			width: $WIDTH_LAYOUT_LISTASSIGNE
			height: auto

div.DivForm
	div.DivPercentageProgress
		position: relative
		height: 40px
		width: 100%
		+setBoxSizing(border-box)
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		div#divSubPercentageProgress
			position: absolute
			height: $HEIGHT_LAYOUT_PERCENTAGE
			width: 30%
			overflow: hidden
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		span
			position: relative
			color: white
			margin-left: 44%
			padding-top: $SPACE_LITTLE
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivForm
	div.DivCard, div.DivCardFinish
		width: 100%
		height: 100%
		margin-bottom: $SPACE_LITTLE
		margin-top: $SPACE_TINY
		div.DivContainerIcon
			position: relative
			display: flex
			flex-direction: row
			justify-content: flex-end
			padding: 0px
			div.DivBorderImage
				display: flex
				justify-content: center
				align-items: center
				width: $WIDTH_LAYOUT_BORDERIMAGE
				height: $HEIGHT_LAYOUT_BORDERIMAGE
				margin-right: $SPACE_LITTLE
				background-color: white
				+setBorderRadius(50%)
				img#imageQoute
					width: $SIZE_ICON_XLDPI
					height: $SIZE_ICON_XLDPI
					object-fit: cover
				img#imageApprove
					width: $SIZE_ICON_XLDPI
					height: $SIZE_ICON_XLDPI
					object-fit: cover

div.DivCard, div.DivCardFinish
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivContainerIcon
	padding-left: $SPACE_LITTLE
	padding-right: $SPACE_LITTLE
	padding-bottom: $SPACE_LITTLE
	padding-top: $SPACE_LITTLE
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	margin-top: $SPACE_HEADERGENERAL_TOP
	padding-bottom: $SPACE_HUGE

div.DivVerifierGeneral, div.DivCenterGeneral
	position: relative
	margin: $SPACE_ENORMOUS
	img#imageLogoHeader
		top: 0px
		right: 0px
	div#divMainDashboard
		div.DivContainer
			margin-top: $SPACE_HUGE

div.DivMainGeneral, div.DivMainAsideSearch
	margin-top: $SPACE_MEDIUM
	width: 100%
	height: 100%
	form
		ul.UlNotification
			list-style: none
			li.ListNotification
				display: flex
				padding: $SPACE_HUGE
				margin: $SPACE_SMALL $SPACE_ZERO
				+setBorderRadius($SPACE_LITTLE)
				div.DivTimeNotification
					display: contents
					p
						margin-right: 0px
			li.ListUnRead
				position: relative
				div.DivNotificationAlert
					width: 30px
					height: 30px
					+setBorderRadius(50%)
					background-color: #EB6924
					position: absolute
					top: -15px
					right: -15px

div.DivMainAsideSearch
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	align-content: stretch
	flex-basis: $WIDTH_LAYOUT_SIGNIN
	div.DivTableListDownload
		margin-left: 0px
		color: black
		table.TableListDetails
			max-width: inherit
			table-layout: inherit
			td:nth-child(1)
				width: 80%
				text-align: left
			td:nth-child(2)
				width: 10%
			td:nth-child(3)
				width: 10%
				padding-right: 0

div.DivContainerHorizontal, div.DivContainerVertical, div.DivContainerListHorizontal
	width: 100%
	height: 100%
	margin-top: $SPACE_MEDIUM
	display: flex
	flex-wrap: nowrap
	div.DivButtonAddDocument
		align-items: center
		margin-top: 0px
		label.LabelAttachment
			margin-right: 10px
			background: transparent
			input[type=button].ButtonAdd
				margin-top: 0px
div.DivContainerListHorizontal
	flex-direction: column
div.DivContainerHorizontal
	flex-direction: row
	align-items: center
	div.DivContainer
		margin-left: $SPACE_SMALL
		margin-right: $SPACE_SMALL
		display: flex
		flex-direction: column
	div.DivContainer:first-of-type
		margin-left: 0px
	div.DivContainer:last-of-type
		margin-right: 0px
div.DivContainerVertical
	flex-direction: column

div.DivForm
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivFormThreeHorizontalContainer
	display: grid
	grid-template-columns: 1fr 0.8fr 1fr

form div.DivForm fieldset, div.DivForm, div.DivCard, div.DivCardFinish
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormHorizontalContainerForReimbursement
		display: flex
		justify-content: space-between
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap // nowrap
		div.DivListChart
			margin-top: $SPACE_MEDIUM
			align-self: flex-start
			ul
				height: $HEIGHT_CHART_LIST
				overflow-y: scroll
	div.DivFormHorizontalContainer
		flex-direction: row
		div.DivForm2Column
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-left: 0px
		div.DivForm2Column:last-of-type
			margin-right: 0px
	div.DivFormVerticalContainer
		flex-direction: column
		div.DivForm2Column
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-top: 0px
		div.DivForm2Column:last-of-type
			margin-bottom: 0px
	div.DivForm2Column
		flex-basis: $SIZE_FLEX_BASIS
		flex-grow: 1
		flex-shrink: 1
		span.SpanActiveStatus, i.IActiveStatus
			width: $WIDTH_LABEL_POLICYGENERAL_STATUS
			text-align: center
	div.DivForm2ColumnWidth
		width: 600px
	div.DivFormHorizontalContainerFlexStart
		justify-content: flex-start

div.DivForm:first-of-type
	margin-top: 0px

div.DivForm:last-of-type
	margin-bottom: 0px

div.DivButtonHorizontal
	margin-bottom: $SPACE_LITTLE
	margin-top: $SPACE_LITTLE

div.DivDivider
	height: 2px
	width: 100%
	background: $THEME_TERTIARY_LIGHT_COLOR

form.FormRadioButtonCenterContainer
	justify-content: center
form.FormRadioButtonDefaultContainer
	justify-content: flex-start
	align-items: baseline
form.FormRadioButtonCenterContainer, form.FormRadioButtonDefaultContainer
	display: flex
	flex-direction: row
	margin: 0

div.DivLabelHorizontalContainer
	display: flex
	grid-template-columns: repeat(3, auto)
	justify-content: space-around
	label.LabelNumberContainer
		text-align: center
		span.SpanNumberValue
			margin: 20px auto 0 auto
			width: 50px
			height: 50px
			border-radius: 50%
			display: grid
			place-content: center
			color: white
			font-size: 1.8em

div.DivRoundButtonContainer
	display: flex
	justify-content: flex-end
	input[type=button]
		margin: 0px !important
	label
		margin-top: $SPACE_LITTLE !important

div.DivCenterButtonContainer
	display: flex
	align-items: center
	flex-direction: row
	width: 100%
	justify-content: center
	flex-wrap: nowrap
	gap: 11px

div.DivBodyGeneral
	div#divMainDashboard
		div.DivSingleForm
			width: 100%

img.ImageValidationStatus
	height: 24px
	vertical-align: bottom
	margin-left: 15px

div.DivContainerForUpload
	display: flex
	gap: $SPACE_MEDIUM
	align-items: center
	figure
		margin: 0
		img
			width: 36px
			height: auto
			cursor: pointer
	label.LabelAttachment
		margin-top: $SPACE_NARROW !important
	div.DivContainerForAttachment
		margin-left: 0px
		font-size: 16px

div.DivLegends
	color: $THEME_TERTIARY_SOFT_COLOR
	span
		padding: $SPACE_LITTLE $SPACE_SMALL
		+setBorderRadius($SPACE_TINY)

.FlexColumn
	display: flex
	flex-direction: column

label.LabelAttachmentDisable
	border-radius: 20px

// SUMMARY FORM START

div.DivSummaryForm
	display: flex
	flex-direction: column
	div.DivContentSummaryForm
		display: flex
		flex-direction: row
		justify-content: space-between
		span
			margin: 0 !important
			font-size: 16px !important
			color: #787878 !important


// SUMMARY FORM END

div.DivLegends
	color: $THEME_TERTIARY_SOFT_COLOR
	span
		padding: $SPACE_LITTLE $SPACE_SMALL
		+setBorderRadius($SPACE_TINY)

// GENERAL - END


// NAVIGATION - START

nav
	margin-top: $SPACE_NAVIGATION_MARGIN
	position: relative
	width: $SIZE_LAYOUT_PROFILECONTAINER
	figure
		margin-bottom: $SPACE_BIG
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-style: solid
			+setBorderRadius(50%)
		div
			position: relative
			margin-left: auto
			margin-right: auto
			margin-bottom: $SPACE_MEDIUM
			width: $SIZE_LAYOUT_PROFILEFRAME
			height: $SIZE_LAYOUT_PROFILEFRAME
			padding: $SPACE_TINY
			border-width: $WIDTH_BORDER_PROFILETHIN
			img#imageProfilePicture, span#spanProfileNameInitial
				width: $SIZE_LAYOUT_PROFILEIMAGE
				height: $SIZE_LAYOUT_PROFILEIMAGE
				border-width: $WIDTH_BORDER_PROFILETHICK
			span#spanProfileNameInitial
				width: $WIDTH_LAYOUT_PROFILENAMEINITIAL
				height: $HEIGHT_LAYOUT_PROFILENAMEINITIAL
				padding-top: $SPACE_SMALL
				padding-bottom: $SPACE_SMALL
	ul.ListNavigation
		li.ListItemNavigation
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				padding-left: $SPACE_SMALL
				li.ListItemSubNavigationRelative
					position: relative
					div.DivNotificationAlert
						width: 30px
						height: 30px
						+setBorderRadius(50%)
						background-color: #EB6924
						position: absolute
						top: -5px
						right: 0
						display: flex
						p
							display: inline-grid
							margin: 5px auto
				li.ListItemSubNavigation
					min-width: $SPACE_NAVIGATION_WIDTH

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	position: relative
	margin: auto
	width: $WIDTH_LAYOUT_SIGNIN
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	header#headerSignIn
		margin-bottom: $SPACE_BIG
		text-align: center
	form.FormSignInShow
		display: none
	form.FormSignInHide
		display: block

div.DiviframeSignIn
	position: absolute
	display: flex
	flex-direction: column
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_ENORMOUS
	bottom: $SPACE_LITTLE
	left: $SPACE_LITTLE
	margin: 0

// SIGN IN - END


// HOME - START

div#divBodyHome
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: flex
	justify-content: flex-start
	flex-direction: column
	perspective: 1000px
	main
		position: absolute
		padding: $SPACE_ENORMOUS
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		+setTransformStyle(preserve-3d)

img#imageLogoHeader
	position: absolute
	width: $WIDTH_LAYOUT_LOGOHEADER
	height: $HEIGHT_LAYOUT_LOGOHEADER
	object-fit: cover

div#divBodyHome
	img#imageLogoHeader
		top: $SPACE_ENORMOUS
		right: $SPACE_ENORMOUS

// HOME - END


// ABOUT - START

img#imageLogoAbout
	display: block
	margin-left: auto
	margin-right: auto
	width: $WIDTH_LAYOUT_LOGOABOUT
	height: $HEIGHT_LAYOUT_LOGOABOUT
	object-fit: cover

// ABOUT - END


// DASHBOARD -  START

.ChartBar
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_MEDIUM
.ngx-charts-outer
	height: auto !important

div#divMainDashboard
	div.DivDashboardEmeterai
		display: flex
		justify-content: space-evenly
		fieldset
			width: auto
			p
				width: 300px
				height: 150px
				margin-left: 0px
				justify-content: center
				align-items: center
				display: flex
				border-radius: 10px
				
// DASHBOARD - END


// DYNAMIC CONTAINER - START

div.DivDynamicContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableContainer
		margin-left: 0px !important
	div.DivSubDynamicContainer
		margin-top: $SPACE_MEDIUM
		form
			width: 100%
			margin-right: 0px
	div.DivListControl
		display: flex
		justify-content: space-between
		align-items: center
	form:last-of-type
		margin-right: 0px
	form.FormInsertConversation
		margin-bottom: 0px
		div.DivConversationGeneral
			display: flex
			flex-direction: column
			div.DivConversationHeader
				display: flex
				justify-content: space-between
				div.DivConversationInfo
					display: flex
					flex-direction: row
					div.DivConversationInfoDetails
						display: flex
						flex-direction: column
						justify-content: center
			div.DivConversationChatType
				width: 25%
			div.DivConversationContent
				display: flex
				flex-direction: column
				div.DivAttachmentContainer
					width: 100%
					box-sizing: border-box
					display: flex
					align-items: center
					border-bottom-left-radius: $RADUIS_LINK_BUTTONCONVERSATION
					border-bottom-right-radius: $RADUIS_LINK_BUTTONCONVERSATION
					margin-top: 0px
					padding-left: $SPACE_LITTLE
					padding-bottom: $SPACE_LITTLE
					height: $HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER
			div.DivConversationProfile
				margin-left: $SPACE_MEDIUM
				+setBorderRadius(50%)

div.DivTransparantContainer
	padding: $SPACE_HUGE
	margin-top: $SPACE_MEDIUM
	+setBorderRadius($RADIUS_LAYOUT_FORM)

// DYNAMIC CONTAINER - END


// EXTRA - START

div.DivFormHorizontalContainer
	div.DivForm2Column
		div.DivImageCapture
			img
				width: 100%
				height: 100%

// EXTRA - END


// TABLE - START

div.DivTableContainer
	// margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableControl, div.DivTableControlMaterai
		display: flex
		justify-content: space-between
		align-items: center
		div.DivTableSubControl, div.DivTableSubControlMaterai
			display: flex
			justify-content: space-between
			align-items: center
	ul.ListTableThumbnails, table, ul.ListTableTicket, ul.ListTableConversation
		margin-top: $SPACE_LARGE
		margin-bottom: $SPACE_LARGE
	ul.ListTableThumbnails, ul.ListTableTicket, ul.ListTableConversation
		display: flex
		list-style-type: none
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		li
			margin: 0px
			padding: $SPACE_LITTLE
	ul.ListTableThumbnails
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap
		li
			width: $WIDTH_TABLE_THUMBNAILSFIGURE
			// height: $HEIGHT_TABLE_THUMBNAILSFIGURE
			height: auto
			figure
				position: relative
				margin-left: auto
				margin-right: auto
				text-align: center
				img
					width: $SIZE_TABLE_THUMBNAILSICON
					height: $SIZE_TABLE_THUMBNAILSICON
					margin-bottom: $SPACE_LITTLE
					border: none
				figcaption
					word-wrap: normal
					white-space: normal
					word-break: break-all
	table
		width: 100%
		thead, tbody
			tr
				td, th
					padding-top: $SPACE_MEDIUM
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: $SPACE_MEDIUM
					word-wrap: normal
					white-space: normal
					word-break: normal
					text-align: center
					figure
						img
							width: $SIZE_TABLE_THUMBNAILSICONSMALL
							height: $SIZE_TABLE_THUMBNAILSICONSMALL
					span.SpanScore
						padding: 10px 14px
						border-radius: 50%
					span.SpanScoreSquare
						padding: 10px 14px
						border-radius: 10%
				td:first-of-type, th:first-of-type
					padding-left: $SPACE_MEDIUM
				td:last-of-type, th:last-of-type
					padding-right: $SPACE_MEDIUM
				td.TableDataCenter
					display: flex
					justify-content: center
			tr.TableRowSelected
				background-color: #F26F21
				border: none
				gap: 0px
				cursor: pointer
			tr.TableRowDetail
				cursor: pointer
		thead
				th:first-of-type
					+setBorderRadiusLeft($RADIUS_TABLE_GENERAL)
				th:last-of-type
					+setBorderRadiusRight($RADIUS_TABLE_GENERAL)
		tbody
			text-align: center
			tr:first-of-type
				td:first-of-type
					+setBorderRadiusTopLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusTopRight($RADIUS_TABLE_GENERAL)
			tr:last-of-type
				td:first-of-type
					+setBorderRadiusBottomLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusBottomRight($RADIUS_TABLE_GENERAL)
	table.TableListDetails
		padding: 0px
		margin-top: $SPACE_LITTLE
		margin-bottom: 0px
		max-width: $WIDTH_TABLE_ATTACHMENT
		table-layout: fixed
		td
			padding: 0px
			figure
				margin: 0px
				img
					margin-top: $SPACE_TINY
		td:first-of-type
			text-align: right
			padding-left: 0px
		td:nth-child(1)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
		td:nth-child(2)
			width: $WIDTH_TABLE_ATTACHMENTBIG
			overflow: hidden
			text-overflow: ellipsis
		td:nth-child(3)
			width: $WIDTH_TABLE_ATTACHMENTMEDIUM
		td:nth-child(4)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
			padding-right: 0px
	table.TableListDetailsAttachment
		td:last-of-type
			position: relative
			flex-flow: column wrap
			align-items: center
			div.DivMultipleLabelAttachment
				justify-content: center
				gap: $SPACE_TINY
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
	table.TableListReportMonitoring, table.TableListPromoCode
		display: block
		border-collapse: unset
		overflow-x: scroll
		thead, tbody
			tr
				th, td
					min-width: 200px
				th:first-of-type, td:first-of-type
					padding-left: 10px
				th:last-of-type, td:last-of-type
					padding-right: 10px
	table.TableListReportMonitoring, table.TableListPromoCode
		display: block
		border-collapse: unset
		overflow-x: scroll
		thead, tbody
			tr
				th, td
					min-width: 200px
	ul.ListTableTicket, ul.ListTableConversation
		flex-direction: column
		li
			width: 100%
			padding: $SPACE_SMALL
			display: flex
			align-items: center
			+setBoxSizing(border-box)
			img
				margin-right: $SPACE_MEDIUM
				width: $SIZE_ICON_LDPI
				height: $SIZE_ICON_LDPI
			div.DivTicketItem, div.DivConversationGeneral
				display: flex
				flex-direction: column
				flex-grow: 1
				div.DivTicketSubItem, div.DivConversationItem
					display: flex
					justify-content: space-between
					dl
						margin: 0px
						flex-grow: 1
						flex-basis: 0
					div.DivConversationStatus
						display: flex
						align-items: flex-start
					div.DivConversationHeader
						display: flex
						flex-flow: row wrap
						align-items: center
						div.DivConversationProfile
							+setBorderRadius(50%)
							span.SpanChatProfileNameInitial
								width: $SPACE_BIG
								height: $SPACE_BIG
								display: flex
								align-items: center
								justify-content: center
			div.DivTicketSubItem:first-of-type, div.DivConversationItem:first-of-type
				margin-bottom: $SPACE_TINY
			div.DivTicketSubItem:last-of-type, div.DivConversationItem:last-of-type
				margin-top: $SPACE_TINY
		li:first-of-type
			+setBorderRadiusTop($RADIUS_LAYOUT_FORM)
		li:end-of-type
			+setBorderRadiusBottom($RADIUS_LAYOUT_FORM)
	ul.ListTableConversation
		li
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			margin-top: $SPACE_MEDIUM
			width: 80%
			+setBoxSizing(border-box)
		li.LiConversationContainerRight
			align-self: flex-end
			div.DivConversationItem
				div.DivConversationHeader
					span
						text-align: right
					div.DivConversationProfile
						margin-left: $SPACE_MEDIUM
						margin-right: 0px
		li.LiConversationContainerLeft
			align-self: flex-start
			flex-direction: row-reverse
			div.DivConversationItem
				flex-direction: row-reverse
				div.DivConversationHeader
					flex-direction: row-reverse !important
					span.SpanConversationDate
						text-align: left
					div.DivConversationProfile
						margin-left: 0px
						margin-right: $SPACE_MEDIUM
		li:first-of-type
			margin-top: 0px
	table.DivLetterTable
		tbody
			tr
				span.SpanAlertLetterGeneral
					display: inline-block
					width: 20px
					height: 20px
					background-color: red
					margin-bottom: 0
					+setBorderRadius(50%)
					border-style: solid

div.DivSubDynamicContainer
	div.DivTableContainer
		margin-left: 0px

div.DivCenterGeneral
	div.DivCenterContainer
		div.DivTableContainer
			margin-left: 0px
	div.DivDynamicContainer
		margin-left: 0px

div.DivMultipleButton
	display: flex
	flex-direction: row
	justify-content: center
	column-gap: 15px

div.DivNoMargin
	margin-left: 0px

div.DivContainerListHorizontal
	div.DivTableContainerHorizontal
		div.DivTableContainer
			div.DivListControl
				div.DivNavigationControlButton
					display: flex
					flex-wrap: wrap
					justify-content: space-between
	div.DivTableContainerColumn
		width: 100%

// TABLE - END


// LOADING - START

div#divCurtainLoading
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: none
	z-index: 10
	div#divLoadingBar
		width: 0%
		height: $HEIGHT_LOADING_BAR
		display: none
	div#divLoadingMessage, img#imageLoadingCircle
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
	div#divLoadingMessage
		width: 100%
		height: 100%
		img#imageLoadingCircle
			width: 100px
			height: 100px
			background-color: none

// LOADING - END


// MODAL - START

div#divCurtainGeneral
	position: fixed
	top: 0svh
	left: 0vw
	right: 0vw
	bottom: 0svh
	height: 100vh
	height: 100svh
	display: none
	z-index: 12
	div.DivForm
		h3
			display: flex
			flex-direction: row
			justify-content: space-between
		.spanModalClose
			text-decoration: none
			cursor: pointer
		div#divButtonContainerVerifyOTP
			display: none

// MODAL - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	display: flex
	padding: $SPACE_LITTLE
	margin-bottom: $SPACE_LITTLE
	box-sizing: border-box
div#divNotificationInstall
	height: 100px
	justify-content: space-between
	align-items: center
	width: 100%
	position: fixed
	left: 0px
	bottom: 0px
	right: 0px
div#divNotificationInstallList
	flex-direction: column
	height: auto
	+setBorderRadius($RADIUS_INPUT_GENERAL)

// NOTIFICATION - END


div#divBodyDashboard
	div#divMainDashboard
		height: 100%
		margin-top: $SPACE_MEDIUM
	div#divMainDashboard
		display: flex
		flex-wrap: nowrap
	div.DivCenterContainer
		margin-top: $SPACE_HUGE
		display: block
		width: 75%
		position: relative
		margin-left: auto
		margin-right: auto
		div.DivForm
			div.DivHeaderHeadline
				display: flex
				justify-content: space-between
				div.DivHeaderStatus
					display: flex
					align-items: flex-start
					justify-content: space-between
					span
						text-align: center
						margin-left: $SPACE_LITTLE
						margin-right: $SPACE_LITTLE
						margin-top: 0px
						padding: $SPACE_LITTLE
						+setBorderRadius($RADIUS_BUTTON_GENERAL)
					span:first-of-type
						margin-left: 0px
					span:last-of-type
						margin-right: 0px
				div.DivSubHeaderHeadline
					label
						margin: 0
					h2
						margin-top: 5px
						margin-bottom: 5px
			fieldset
				margin-top: 0px
				div.DivForm2Column
					div.DivHeaderInfo
						margin-top: $SPACE_LITTLE
						display: flex
						justify-content: space-between
						dl
							margin: 0px
							flex-grow: 1
							flex-basis: 0
							dd
								margin: 0
				div.DivFormHorizontalContainer
					div.DivHeaderList
						align-self: flex-start
						ul
							li
								padding: 5px 10px
								width: fit-content
								margin-right: 0
								span
									margin: 0
								+setBorderRadius($RADIUS_BUTTON_GENERAL)

div.DivProfileGeneral
	display: flex
	justify-content: space-between
	div.DivProfileInfo
		display: flex
		flex-direction: row-reverse
		div.DivProfileInfoDetails
			display: flex
			flex-direction: column
			justify-content: center
		div.DivProfilePhoto
			margin-right: $SPACE_SMALL
			+setBorderRadius(50%)
			span.SpanChatProfileNameInitial
				width: $SPACE_BIG
				height: $SPACE_ENORMOUS
				padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
				display: flex
				justify-content: center
	
// CALCULATE PREMI FORM - START

form.CalculateForm
	width: 50%
	margin-left: auto
	margin-right: auto
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputText
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				align-items: flex-end
				span.SpanRupiah
					margin-right: 30px
		fieldset:last-of-type
			margin-bottom: 0px

// CALCULATE PREMI - END


// PRODUCT INFORMATION - START

div.DivTableContainer
	ul.ListTableThumbnails
		li
			div.DivButtonNextCalculate
				display: flex
				justify-content: center
				div.DivButtonHide
					display: none

// PRODUCT INFORMATION - END

/* THIRD PARTY  START */

// SINGLE DROPDOWN - START

div.DivFormSingleDropdown, div.DivFormLongTextDropdown
	.ng-select
		width: 100%
		.ng-dropdown-panel
			margin-left: 0px
			.ng-dropdown-panel-items
				min-height: 30px
		.ng-select-container
			height: $SPACE_ENORMOUS
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				display: flex
				align-items: center
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 1em
					font-weight: 400
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					margin-top: $SPACE_LITTLE
					.ng-value-label
						margin-left: $SPACE_TINY
						white-space: normal
						line-height: initial
						margin-top: 0px
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-input
					padding-left: 10px
					margin-top: $SPACE_LITTLE
				.ng-input>input
					margin-top: 0px
	.ng-select-multiple.NgSelectMultipleSinggleDropdown
		margin-bottom: 22.5px
		.ng-select-container
			margin-top: $SPACE_SMALL
			height: auto
			.ng-value-container
				padding: $SPACE_LITTLE
				gap: $SPACE_LITTLE
				+setBorderRadius(12px)
				.ng-value
					z-index: 1
					position: relative
					padding: 4px 38px 4px $SPACE_LITTLE
					margin: $SPACE_ZERO
					border: $BORDER_THIN solid
					+setBorderRadius($SPACE_LITTLE)
					.ng-value-icon
						font-size: $SPACE_ZERO
						position: absolute
						right: $SPACE_LITTLE
						display: inline-block
						width: $SPACE_MEDIUM
						height: $SPACE_MEDIUM
					.ng-value-label
						margin-left: $SPACE_ZERO
				.ng-input
					padding-left: $SPACE_ZERO
					margin-top: $SPACE_ZERO
					input
						height: auto
						margin: $SPACE_ZERO
						+setBorderRadius($SPACE_ZERO)
	.ng-select-multiple.NgSelectMultipleSinggleDropdown, .ng-select.NgSelectSinggleDropdown
		.ng-select-container
			margin-top: $SPACE_SMALL
			.ng-value-container
				.ng-placeholder
					font-size: $FONT_LABEL_PROPERTY
			.ng-clear-wrapper
				display: flex
				align-items: center
				padding-right: 28px
				margin-top: $SPACE_ZERO
				margin-bottom: $SPACE_ZERO
				.ng-clear
					margin-top: $SPACE_ZERO
					margin-bottom: $SPACE_ZERO 
		.ng-dropdown-panel
			padding-left: $SPACE_ZERO
			+setBorderRadius($SPACE_LITTLE)
			.ng-dropdown-panel-items
				.ng-option
					margin: $SPACE_ZERO
					+setBorderRadius($SPACE_LITTLE)
					.ng-option-label
						margin: $SPACE_LITTLE
				.ng-option-disabled
					font-family: $URL_FAMILYPRIMARY_PRIMARY
					font-size: $FONT_LABEL_PROPERTY
					margin: $SPACE_LITTLE
	.ng-select.NgSelectSinggleDropdown
		.ng-select-container
			height: $HEIGHT_INPUT_GENERAL
			margin-bottom: 22.5px
			.ng-value-container
				.ng-value
					margin-left: $SPACE_TINY
					margin-bottom: $SPACE_ZERO
					margin-top: $SPACE_ZERO
				.ng-placeholder
					font-size: $FONT_LABEL_PROPERTY
					margin-left: $SPACE_LITTLE
					&:empty
						display: none
		.ng-dropdown-panel
			top: $HEIGHT_INPUT_GENERAL
	.ng-select-multiple.NgSelectMultipleSinggleDropdown
		margin-bottom: 22.5px
		.ng-select-container
			margin-top: $SPACE_SMALL
			height: auto
			.ng-value-container
				padding: $SPACE_LITTLE
				gap: $SPACE_LITTLE
				+setBorderRadius(12px)
				.ng-value
					z-index: 1
					position: relative
					padding: 4px 38px 4px $SPACE_LITTLE
					margin: $SPACE_ZERO
					border: $BORDER_THIN solid
					+setBorderRadius($SPACE_LITTLE)
					.ng-value-icon
						font-size: $SPACE_ZERO
						position: absolute
						right: $SPACE_LITTLE
						display: inline-block
						width: $SPACE_MEDIUM
						height: $SPACE_MEDIUM
					.ng-value-label
						margin-left: $SPACE_ZERO
				.ng-input
					padding-left: $SPACE_ZERO
					margin-top: $SPACE_ZERO
					input
						height: auto
						margin: $SPACE_ZERO
						+setBorderRadius($SPACE_ZERO)
	.ng-select-multiple.NgSelectMultipleSinggleDropdown, .ng-select.NgSelectSinggleDropdown
		.ng-select-container
			margin-top: $SPACE_SMALL
			.ng-value-container
				.ng-placeholder
					font-size: $FONT_LABEL_PROPERTY
			.ng-clear-wrapper
				display: flex
				align-items: center
				padding-right: 28px
				margin-top: $SPACE_ZERO
				margin-bottom: $SPACE_ZERO
				.ng-clear
					margin-top: $SPACE_ZERO
					margin-bottom: $SPACE_ZERO 
		.ng-dropdown-panel
			padding-left: $SPACE_ZERO
			+setBorderRadius($SPACE_LITTLE)
			.ng-dropdown-panel-items
				.ng-option
					margin: $SPACE_ZERO
					+setBorderRadius($SPACE_LITTLE)
					.ng-option-label
						margin: $SPACE_LITTLE
				.ng-option-disabled
					font-family: $URL_FAMILYPRIMARY_PRIMARY
					font-size: $FONT_LABEL_PROPERTY
					margin: $SPACE_LITTLE
	.ng-select.NgSelectSinggleDropdown
		.ng-select-container
			height: $HEIGHT_INPUT_GENERAL
			margin-bottom: 22.5px
			.ng-value-container
				.ng-value
					margin-left: $SPACE_TINY
					margin-bottom: $SPACE_ZERO
					margin-top: $SPACE_ZERO
				.ng-placeholder
					font-size: $FONT_LABEL_PROPERTY
					margin-left: $SPACE_LITTLE
					&:empty
						display: none
		.ng-dropdown-panel
			top: $HEIGHT_INPUT_GENERAL
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		margin-left: 0px
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE
	input[type=button].ButtonAction
		width: $SPACE_ENORMOUS
		+setBorderRadius($SPACE_LITTLE)
		margin: 0
		height: $SPACE_ENORMOUS

div.DivFormSigninDropdown
	margin-top: $SPACE_ZERO
	.ng-select.NgSelectSinggleDropdown
		padding-left: $SPACE_NARROW
		width: 200px
		padding-top: $SPACE_ZERO
		margin-top: $SPACE_ZERO
		margin-bottom: $SPACE_ZERO
		.ng-select-container
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW
			.ng-value-container
				position: relative
				.ng-value
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_NARROW
					.ng-value-label
						font-size: $FONT_LABEL_PROPERTY
				.ng-input
					display: none
			.ng-clear-wrapper
				margin-top: $SPACE_NARROW
				margin-bottom: $SPACE_NARROW
		.ng-dropdown-panel-items
			.ng-option
				.ng-option-label
					display: block

ng-select.NgSelectForSearch
	background-color: #E6E6E6
	border-radius: 12px
	padding-left: 10px
	ng-dropdown-panel
		background-color: #E6E6E6
	div.ng-select-container
		div.ng-value-container
			width: 100px
			overflow: hidden
			white-space: nowrap
			text-overflow: ellipsis
			div.ng-value
				display: flex
				align-items: center
				span.ng-value-label
					margin: 0
					white-space: pre-line
					word-break: break-word
					padding-top: 5px
					padding-bottom: 5px
			div.ng-input
				margin-top: 5px


//SINGLE  DROPDOWN - END


// MULTIPLE DROPDOWN - START

div.DivFormMultipleDropdown
	.ng-select
		width: 100%
		.ng-dropdown-panel
			margin-left: 0px
		.ng-select-container
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				padding: 5px
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_TINY
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-icon
						display: inline-block
						padding: $SPACE_TINY $SPACE_TINY
						padding-top: 10px
						background-color: #F7931E
					.ng-value-icon.left
						// margin-right: $SPACE_TINY
						margin-left: -$SPACE_LITTLE
						margin-top: 0px
						margin-bottom: 0px
						border-radius: 50%
						height: 20px
						border: none
						opacity: 0%
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						padding-top: 10px
						padding-bottom: 10px
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-value::before
					content: "-"
					width: 10px
					height: 10px
					margin-left: 5px
					margin-right: -10px
					background-color: #F7931E
					+setBorderRadius($SPACE_LITTLE)
					color: white
					text-align: center
					padding-left: 5px
					padding-right: 5px
					padding-top: 8px
					padding-bottom: 2px
					font-size: 2em
				.ng-input>input
					margin-left: $SPACE_TINY
		.ng-clear-wrapper
			position: relative
			margin-top: auto
			margin-bottom: auto
			margin-right: 25px
			padding: 0px
			span
				margin-bottom: 0px
				margin-top: 0px
				padding-left: 7px
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// MULTIPLE DRODOWN - END


// REPORT LIST

div.DivReportList
	div#divBodyDashboard
		div#divMainDashboard
			form div.DivForm fieldset:last-of-type
				margin-left: -2px

form
	div.DivForm
		div.DivMatFormFieldYearAndMonth,
			.mat-form-field
				width: 100%
	.mat-form-field
		width: 100%
		
// REPORT LIST


// RETURN AMOUNT START

div.DivForm
	div.DivFormReturnAmount
		fieldset
			display: flex
			justify-content: space-between
			height: 0px


// RETURN AMOUNT END


// DIV UPLOAD DOCUMENT STAMP START //

div.DivForm
	fieldset
		div.DivTableContainer
			ul.ListTableThumbnails
				margin-top: -30px
				li
					figure
						figcaption
							color: #787878

// DIV UPLOAD DOCUMENT STAMP END //


// TD FOR OVERFLOW TEXT START

div.DivTableContainer
	table
		tbody
			tr
				td.TdWordingOverflow
					word-break: break-word
				input[type=button].ButtonRetry
					color: white
					font-size: 12px

// TD FOR OVERFLOW TEXT END


// SINGLEDROPDOWN OVERFLOW START

div.DivFormLongTextDropdown
	.ng-dropdown-panel
		margin-left: 0px
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE
				white-space: pre-wrap
				word-wrap: break-word
				.ng-option-label
					word-break: break-word
	.ng-select
		white-space: normal
		width: 100%
		.ng-select-container
			height: 60px
			.ng-value-container
				.ng-value
					.ng-value-label
						word-break: break-word
				.ng-input
					margin-top: 15px
				.ng-value.ng-star-inserted
					font-size: 16px
			span
				margin-top: 15px

// SINGLEDROPDOWN OVERFLOW END


// GCG ONLINE - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					display: flex
					flex-wrap: wrap
					justify-content: center
					column-gap: 2%
					margin-top: $SPACE_MEDIUM

div.DivMainGCGOnlineOverview
	div.DivSingleForm
		form
			div.DivForm
				+setScrolllbarWidthHeight($WIDTH_SCROLLBAR_VERTICAL, 100%)
				h3
					text-align: center
				fieldset.FieldsetContainerOverview
					max-height: 50vh
					overflow-y: scroll
				div.DivCheckBoxContainer
					margin-top: $SPACE_LITTLE

div.DivMainGCGOnlineDetail
	div.DivSingleForm
		div.DivDynamicContainer
			margin-left: $SPACE_ZERO
		form
			div.DivForm
				label
					span.SpanStatusHistoryApproval
						margin-left: $SPACE_LITTLE
						display: inline-block
						padding: $SPACE_NARROW $SPACE_LARGE
						+setBorderRadius($SPACE_TINY)
				div.DivContainerForUpload
					div.DivButton
						input[type="button"]
							margin-top: $SPACE_LITTLE
							margin-bottom: $SPACE_LITTLE

div.DivMainGCGOnlineOverview div.DivSingleForm form div.DivForm fieldset,
div.DivMainGCGOnlineDetail div.DivSingleForm form div.DivForm fieldset.FieldsetContainerList
	padding: $SPACE_LITTLE $SPACE_LARGE
	box-sizing: border-box

// GCG ONLINE - END


/* E-Materai - START */

div.DivBodyEmaterai
	display: flex
	flex-direction: column

div.DivButtonDashboardEmaterai
	display: flex
	justify-content: space-between
	margin-top: 20px

div.DivSingleForm
	div.DivFormVerticalContainer
		label.LabelEmateraiSurveyStatus
			a
				+setBorderRadius($SPACE_TINY)
				padding: 2px 25px


// PDF Viewer - Start

div.DivTableContainer
	#divPdf
		margin: auto
		position: relative
		#divBox
			width: 80px
			height: 80px
			color: rgba(0, 0, 0, 0.87)
			cursor: move
			display: flex
			justify-content: center
			align-items: center
			background-size: contain
			background-repeat: no-repeat
			background-position-x: center
			position: relative
			z-index: 1
		.DivPdfViewer
			position: absolute
			display: block
			height: 100%
			width: 100%
			.ng2-pdf-viewer-container
				height: 100% !important
				overflow-x: unset !important
				.pdfViewer
					height: 100% !important
					.page
						height: 100% !important
						width: 100% !important
						margin: 0px !important
						border: 0px !important
						canvas
							width: 100% !important
							height: 100% !important
						.canvasWrapper
							width: 100% !important
							height: 100% !important
						.textLayer
							width: 100% !important
							height: 100% !important
	div.DivTableControlMaterai
		margin-bottom: 1em
		div.DivTableSubControlMaterai
			display: flex
			align-items: center
			margin: auto
			width: 250px
			input[type=button]
				margin-top: 0
			input[type=button]:first-of-type
				margin-top: 0
			input[type=button]:last-of-type
				margin-bottom: 0

div.DivTransparent
	margin: auto !important
	background-color: rgba(255, 255, 255, 0) !important

// PDF Viewer - End


/* E-Materai - END */


// REIMBURSEMENT - START

div#divBodyDashboard
	form.FormReimbursement
		width: 25%
	div.DivTableReimbursment
		width: 100%


div.DivFormHorizontalContainerForReimbursement
	div.DivForm2Column
		flex: 1
		p
			font-size: 14px !important
			font-style: italic
			margin-top: -10px !important
			font-family: $URL_FAMILYPRIMARY_PRIMARY !important

div.DivForm
	div.DivRadioButtonContainer
		input#checkboxReimbursementDetail
			min-width: 15px
		label
			margin-left: 10px

// REIMBURSEMENT - END


// CONTAINER FILE ATTACHMENT DETAIL START

div.DivForm
	fieldset
		p
			font-size: $SPACE_LITTLE
	div.DivContainerAttachedment
		ul
			display: flex
			flex-wrap: wrap
			li
				margin-left: $SPACE_MEDIUM
				margin: 0px
				padding: $SPACE_LITTLE
				figure
					margin: 0px
					padding: 0px
				figcaption
					width: 100px
					word-break: break-word
					img
						width: 100px
				div.DivButton
					display: flex
					justify-content: center

	div#divFormAttachment
		div.DivAttachmentContainer
			justify-content: flex-start
			align-items: center
			div.DivFormVerticalContainer:nth-child(1)
				margin-right: $SPACE_LITTLE
			div.DivFormVerticalContainer:nth-child(2)
				margin-left: $SPACE_LITTLE
				label, span
					margin: $SPACE_TINY
				span
					margin-top: auto
				label
					margin-bottom: auto
			div.DivFormVerticalContainer
				figure.FigureAttachment
					margin: 0 $SPACE_LITTLE
					cursor: pointer
					img
						display: block
						margin: auto
						width: calc($SIZE_ICON_LDPI * 1.5)
						height: calc($SIZE_ICON_LDPI * 1.5)
				div.DivFormHorizontalContainer
					align-items: center
					span, label
						margin: $SPACE_TINY 0
		div.DivTableContainer
			margin-left: 0
			table.TableListDetails
				td:nth-child(1)
					width: $HEIGHT_INPUT_GENERAL
					figure
						width: 100%
					img
						display: block
						margin: auto
				td
					p
						margin: auto

// CONTAINER FILE ATTACHMENT DETAIL END


// DIVFORM CHART

form.FormGenerateTable
	div.DivForm
		div.ngx-charts-outer
			svg.ngx-charts
				width: 350px
				g.pie-chart
					transform: translate(150px, 120px)
		ngx-charts-legend
			height: inherit
			div
				width: 250px !important
				height: inherit
				display: flex
				div.legend-wrap
					margin: auto
					height: auto

// DIVFORM CHART


// E-BUDGETING - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					display: flex
					flex-wrap: wrap
					justify-content: center
					column-gap: 2%
					margin-top: $SPACE_MEDIUM
		div.DivMainAsideContent
			width: 100%
			form
				width: 100%

form.FormTransparent
	div.DivForm
		padding: $SPACE_ZERO
		margin-top: $SPACE_LARGE
		display: flex
		flex-wrap: wrap
		column-gap: 1%
		fieldset
			width: 35%
			padding-left: $SPACE_ZERO

div.DivTableContainer
	form
		margin: $SPACE_ZERO
		div.DivFormButtonContainer
			justify-content: center
			column-gap: $SPACE_LITTLE

			
div.DivBodyEbudgeting
	div.DivForm
		div.DivFormHorizontalContainer
			justify-content: space-evenly
			text-align: center
		div.DivFormContainerBudgetAllocation
			display: flex
			flex-wrap: wrap
			column-gap: 4%
			row-gap: $SPACE_LARGE
			align-items: center
			padding: $SPACE_LARGE
			div.DivContainerBudgetAllocation
				display: flex
				justify-content: center
				column-gap: $SPACE_HUGE
				flex-wrap: wrap
		div.DivFormContainerBudgetAllocation > div
			width: 48%
	div.DivEbudgetingSummary
		display: flex
		justify-content: flex-start
		flex-wrap: wrap
		column-gap: 5%
		row-gap: $SPACE_TINY
		margin-bottom: $SPACE_LARGE
	div.DivEbudgetingDaashboadDescription
		ul
			display: flex
			flex-wrap: wrap
			justify-content: center
			column-gap: $SPACE_ENORMOUS
			row-gap: $SPACE_LITTLE
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LITTLE
			li
				display: flex
				margin: $SPACE_ZERO
				align-items: center
				width: max-content
				div
					width: $WIDTH_LEGEND_CHART
					height: $HEIGHT_LEGEND_CHART
					margin-right: $SPACE_SMALL
					+setBorderRadius($RADIUS_LEGEND_CHART)
			
div.DivBodyEbudgetingApproval
	div.DivForm
		label
			span.SpanStatusHistoryApproval
				margin-left: $SPACE_LITTLE
				display: inline-block
				padding: $SPACE_NARROW $SPACE_LARGE
				+setBorderRadius($SPACE_TINY)

// E-BUDGETING - END


// REPORT MONITORING LIST - START

div#divMenuAllProductListContainer
	display: none
	div.DivTableContainerHorizontal
		text-align: center
		h3
			text-align: left !important

form
	div.DivForm2ColumnButton
		display: flex
		gap: 10px
		input[type=button]
			margin: 10px 0
			font-size: 1em

// REPORT MONITORING LIST - END


// BUBBLE STATUS - START

div#divStatusEpolicyMonitoring
	div.DivForm
		h3
			margin-bottom: 15px
		label#labelSubHeader
			margin-bottom: 15px
			text-align: center
		div.DivFormHorizontalContainer
			justify-content: space-around
			div.DivFormVerticalContainer
				align-items: center
				label
					width: 250px
					text-align: center
				div.DivBubleStatus, div.DivBubleStatusActive, div.DivBubleStatusNonActive, div.DivBublePublic, div.DivBubleStatusWarning
					width: 250px
					height: 70px
					display: flex
					justify-content: center
					align-items: center
					border-radius: 20px
					label
						margin: 0
						color: $THEME_TERTIARY_SOFT_COLOR
						font-weight: bold
						font-size: 2em
				div.DivBubleStatusActive
					background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
				div.DivBubleStatusNonActive
					background-color: $THEME_INDICATOR_FAIL_NORMAL_COLOR
				div.DivBubleStatusWarning
					background-color: $THEME_INDICATOR_SEVERITY_LOW_COLOR
				div.DivBublePublic
					background-color: $THEME_CONVERSATION_PUBLIC_COLOR

// BUBBLE STATUS - END


// BRINSPRO - START

div.DivBodyGeneral
	div.DivMainGeneral, div.DivContainerListHorizontal
		div.DivTableContainer
			table
				tr.TableRowTotal
					background-color: white
					color: #1476BC
			table.TableWithMarginLeft
				margin-left: -15px
			table.TableWithInput
				td:nth-child(1)
					width: 10%
				td:nth-child(2)
					width: 90%
		div.DivFormContainerProjectDetail
			div.DivFormProjectDetail
				display: flex
				flex-direction: row
				div.DivTableProjectDetail
					width: 70%
					table
						td
							padding-top: 30px
							padding-bottom: 30px
				div.DivFormProgress
					width: 30%
					margin-top: 25px
		div.DivForm
			div.DivFormHorizontalPercentage
				display: flex
				flex-direction: row
				div.DivContainerPercentage
					width: 73%
					height: 30px
					padding-top: 0.5px
					margin-left: 20px
					text-align: center
					background-color:#E0E0E0
					div.DivPercentage
						height: 30px
						padding-top: 0.5px
					div.DivPercentageBlue
						background-color: #045093
					div.DivPercentageOrange
						background-color: #E7903D
			div.DivSpanForDivision
				gap: 5px
				display: flex
				flex-direction: row
				flex-wrap: wrap
		div.DivFormUploadDetail
			background-color: #547CB3
			div.DivFormContainerAttachmentFile
				display: grid
				grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
				gap: 10px
				div.DivFormContainerUploadDetail
					display: flex
					flex-direction: column
					figure
						img
							width: 150px
							height: 100px
					div.DivContainerForAttachment
						margin-left: 75px
					div.DivButton
						margin-left: 100px
		div.DivFormHorizontalContainer
			div.DivDropdownWithPlaceholder
				.ng-select
					.ng-placeholder
						color: #aba7a7
						opacity: 1
						font-family: helveticaneuecyr_roman
						margin-top: 10px
						margin-left: 10px
						font-size: 1.1em
div.DivBodyGeneral
	div.DivMainGeneral
		div.DivFormContainerDashboardBrinsproDate
			width: 30%
			.mat-mdc-form-field-infix
				width: 325px
		div.DivFormContainerDashboardBrinspro
			display: flex
			flex-direction: row
			width: 100%
			div.DivFormFirstColumn
				width: 70%
				margin-top: 20px
				margin-right: 20px
				div.DivFormContainerProject
					display: flex
					flex-direction: row
					gap: 18px
					height: 100%
					div.DivFormProject
						margin-top: 0px
						margin-bottom: 0px
						div.DivProjectContent
							text-align: center
					div.DivFormProjectStatus
						width: 60%
					div.DivFormProjectTotal
						width: 40%
				div.DivFormContainerDashboardChart
					display: flex
					flex-direction: column
					height: 250px
					overflow-y: auto
					scrollbar-color: #888 #f1f1f1
					div.DivContainerChart
						width: 100%
						div.DivChartBase
							width: 97%
							padding-top: 0.5px
							height: 25px
							text-align: center
							background-color:#E0E0E0
							div.DivChart
								height: 25px
								padding-top: 0.5px
			div.DivForm
				height: 100%
			div.DivFormSecondColumn
				margin-top: 20px
				width: 28%
				div.DivProjectBudgetContent
					margin-top: 40px
					margin-bottom: 40px
				div.DivForm
					div.DivFormContainerLegend
						margin-left: 10px
						display: flex
						flex-direction: row
						div.DivFormLegendColor
							display: flex
							flex-direction: column
							div.DivBarChartLegend
								width: 17px
								height: 17px
								margin-top: 5px
								margin-bottom: 5px
								border-radius: 5px
						div.DivFormLegendLabel
							display: flex
							flex-direction: column
							align-items: flex-start
		div.DivFormContainerDashboardBrinsproLast
			margin-top: -143px
			div.DivFormSecondColumnLast
				margin-top: 162px

// BRINSPRO - END


// CMS - START

form.FormContainerCMS
	div.DivContainerAttachmentBanner
		div.DivContainerHorizontal
			display: flex
			flex-direction: row
			margin-top: 0px
			img#imageBanner
				width: 30px
				margin-top: 15px
				margin-left: 10px
		div.DivContainerVertical
			margin-top: $SPACE_LITTLE
			margin-left: $SPACE_LITTLE
			label
				margin-top: $SPACE_THIN
				margin-bottom: 0px
				font-size: 0.7em
				font-style: italic
			span
				margin-top: $SPACE_THIN
				margin-bottom: 0px
				font-size: 0.8em
		img
			margin-top: $SPACE_MEDIUM
			max-width: 100%
	div.DivContainerPromoCode
		div.DivFormSingleDropdown
			select
				background-size: $SPACE_HUGE
		ng-select.NgSelectCoverageName
			margin-top: 13px
			.ng-select-container
				.ng-value-container
					.ng-placeholder
						height: 85%
						display: flex
						align-items: center
						padding-left: $SPACE_TINY
						color: #787878
						font-size: 1.2em
		label.LabelStatusPromoCode
			margin-top: 33px
		label.LabelPriority
			margin-top: 32px
	div.DivHorizontalContainer
		display: flex
		gap: 15px
		div.DivForm2Column
			width: 50%
			textarea
				resize: none
				height: 320px
			textarea#textareaHeaderDescriptionHeader
				resize: none
				height: 420px
			div.DivContainerHorizontal
				height: auto
				flex-direction: row !important
			div.DivImgContainer
				height: 500px
				text-align: center
				background-color: #E6E6E6
				border-radius: 12px
				margin-top: 10px
				img#imageBannerUploadHeader
					height: 500px
					margin: auto
					object-fit: cover
					border-radius: 12px

// CMS - END
